import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./main.css";
import "react-tooltip/dist/react-tooltip.css";
import { ChipChecker } from "./components/ChipChecker";
import MainLayout from "./layout/MainLayout";
import GlobalProvider from "./layout/GlobalProvider";

import { createBrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

import * as Sentry from "@sentry/react";
import FullPageLoading from "./components/FullPageLoading";

const isMobiusPage = window.location.pathname.includes("/mobius");

if (!isMobiusPage) {
  Sentry.init({
    dsn: "https://0f89e7766bf6ae4d4e220ed5f7a77b7a@o4507019801788416.ingest.us.sentry.io/4507927171825664",
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
      Sentry.replayCanvasIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const createRouter = isMobiusPage ? createBrowserRouter : sentryCreateBrowserRouter;

const HomePage = React.lazy(() => import("./pages/Home"));
const EventInfo = React.lazy(() => import("./components/EventInfo"));
const About = React.lazy(() => import("./components/About"));
const MintPage = React.lazy(() => import("./pages/Mint"));
const LoginPage = React.lazy(() => import("./pages/Login"));
const UnauthorizePage = React.lazy(() => import("./pages/Unauthorize"));
const MobiusPage = React.lazy(() => import("./pages/MobiusPage"));
const CountdownPage = React.lazy(() => import("./pages/Countdown"));
const DesktopForbiddenPage = React.lazy(() => import("./pages/Desktop"));
const MintVisualPage = React.lazy(() => import("./pages/MintVisual"));
const ProfileDetails = React.lazy(() => import("./components/ProfileDetails"));
const Contact = React.lazy(() => import("./components/Contact"));

const router = createRouter([
  {
    path: "/",
    element: <GlobalProvider></GlobalProvider>,
    children: [
      {
        path: "/",
        element: <ChipChecker />,
        children: [
          {
            path: "/",
            element: <MainLayout />,
            children: [
              {
                path: "/event/:id",
                element: <EventInfo />,
              },
              {
                path: "/about",
                element: <About />,
              },
              {
                path: "/contact",
                element: <Contact />,
              },
              {
                path: "/profile",
                element: <ProfileDetails />,
              },
              {
                path: "/profile/:id",
                element: <ProfileDetails />,
              },
              {
                path: "/",
                element: <HomePage />,
              },
            ],
          },
        ],
      },
      {
        path: "/desktop",
        element: <DesktopForbiddenPage />,
      },
      {
        path: "/unauthorized",
        element: <UnauthorizePage />,
      },
      {
        path: "/login",
        element: <LoginPage></LoginPage>,
      },
      {
        path: "/mint",
        element: <MintPage />,
      },
    ],
  },
  {
    path: "/countdown",
    element: <CountdownPage />,
  },
  {
    path: "/mobius",
    element: <MobiusPage />,
  },
  {
    path: "/mint-visual",
    element: <MintVisualPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <React.Suspense fallback={<FullPageLoading />}>
      <RouterProvider router={router}></RouterProvider>
    </React.Suspense>
  </React.StrictMode>
);
